html {
  background:#fcf9f4;
    overflow-x: hidden;
}
.card-title {
  text-transform: capitalize;
}
.card-title.featured {
  padding: 20px 15px;
}
.flex-reversed {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.resource-card {
  margin: 10px 0;
  border-radius: 0;
  border: none;
  box-shadow: 0 2px 15px rgba(84,96,103,.25);
}
.resource-page {
  display: block;
  padding:0 15px;
  margin: 0 auto;
  text-align: left;
}
.resource-form {
  padding:15px;
  background: #fff;
}
.resource-form-fields {
  max-width: 600px;
    display: flex;
    flex-direction: column;
}
.resource-page_header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #fff;
}
.resource-page_search {
  
}
.resource-table {
  background: #fff;
  box-shadow: 0 2px 15px rgba(84,96,103,.25);
  
}
.iframe-wrapper {
  max-width:100%;
  min-width: 600px;
  overflow-x: scroll;
  overflow-y: hidden;
}
.graph_embed {
  overflow-y: hidden;
}
.iframe-wrapper iframe {
}
.table th {
  border-top:0;
  background-color: #fcf9f4;
  font-weight: 500;
  color: #000;
}
.table td {
  color: #424770;
  font-weight: 300;
}
tbody {
  border-bottom: 1px #eee solid;
}
.body-font, .body-font:hover {
    color: initial;
    text-decoration: none;
}
.loading-shading-mui {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .3);
}

.loading-icon-mui {
  position: absolute;
  font-size: 20px;
  top: calc(45% - 10px);
  left: calc(50% - 10px);
}
.MuiPaper-rounded-17 {
    border: 1px solid #fff;
}
.code-block {
    width: 100%;
}
.code-block label {
    justify-content: left;
}
.code-block pre {
    background: #f8f8f8;
    border: 1px solid #eeeeee;
    display: block;
    padding: 20px;
}
.code-block pre code {
    width: 100%;
    display: inline-block;
}